import {computed, inject, watch, reactive, ref, unref} from "vue";
import {createStateAccessor} from "#app/vue-common/state.js";
import {DELIVERY_OPTIONS, API_STATUS} from "#app/constants/appContants.js";
import {registerApi} from "#app/api-manager.js";
import toJson from "json-stringify-safe";
import helpers from "@/utils/helpers.js";
import {event} from "vue-gtag";
// import mockData from "@/store/mock.js";
// import mockData from "@/store/mock-test-prod.js";
const key = Symbol("state");

const staticContext = {
	info: "restaurant_menu_info",
	deliveryInfo: "restaurant_delivery_menu_info",
	menu: "menu",
	deliveryMenu: "delivery_menu",
};


export function createState ($, ssrContext) {
	const api = registerApi("restaurant",
		false,
		// import.meta.env.SSR &&
		// (async (params, context, ssrContext) => {
		// 	params.headers = params.headers || {};
		// 	ssrContext?.logger?.log?.({
		// 		level: "info",
		// 		message: `api request: ${toJson(params)}`,
		// 	});
		// 	const response = await restaurantApiRequest(params, ssrContext);
		// 	let body = response.body;
		// 	let respData = {};
		// 	let content;
		// 	if (body) {
		// 		try {
		// 			content = await response.text();
		// 			if (content) {
		// 				respData = JSON.parse(content);
		// 			}
		// 		}
		// 		catch {}
		// 	}
		//
		// 	ssrContext?.logger?.log?.({
		// 		level: "info",
		// 		message: `api response: ${toJson(respData)}`,
		// 	});
		// 	return respData;
		// }),
		{
			getName (params) {
				console.log("params", params);
				return params?.path;
			},
		}).useContext($, ssrContext);
	const accessor = createStateAccessor({
		title: "",
		description: "",
		locale: $.locale || "uk",
		theme: $.cookies.get("theme") || "system",
		themes: ["system", "light", "dark"],
		isCookieAccepted: $.cookies.get("allowCookies"),
		isMobile: true,
		isTablet: false,
		isDesktop: false,
		ignoreStatic: false,
		isInfoStatic: false,
		isMenuStatic: false,
		// defaultDishImg: "default-dish-bg.png",
		emptyDishImage: "",
		availableCategories: new Set(),
		availableSpecials: new Set(),
		availableLangs: [],
		order: {},
		restaurant: {},
		menus: [],
		categories: [],
		tags: [],
		allergens: [],
		allergenOptions: {
			// default options
			backgroundColor: "#F9E6E5",
			fontColor: "#000000",
			iconUrl: "https://static.shaketopay.com.ua/icons/tags/emoji-ambulance.png",
		},
		dishes: [],
		specials: [],
		resMenu: {},
		basket: [],
		wishList: [],
		payment: {},
		activeOrders: {},
		tipOptions: [],
		ccy: "₴",
		isRestaurantOpen: true,
		orderNotAllowed: false,
		isDeliveryOptionPosible: false,
		isDeliveryOptionPresent: false,
		isPickUpOptionPresent: false,
		showAlert: {
			delivery: false,
			deliveryTime: false,
			orderNotAllowed: false,
			closed: false,
		},
		alertContent: {
			delivery: {},
			deliveryTime: {},
			orderNotAllowed: {},
			closed: {},
		},
		productRestrictionError: null, // param available only for error when paying table order. Format {errTitle, errText}
		eatListInfo: {},
		eatList: [],
		promoData: {},
		giveawayData: {},
		appModalOpen: false,
	}, {
		methods: {
			clearState (state, keys = ["restaurant", "resMenu", "current", "order"]) {
				keys.forEach(el => state[el] = {});
			},
			async isStaticCall (state) {
				return await import.meta.env.VITE_STATIC_API_FETCH === "true";
			},
			/** START: STATIC CALLS SERVICES **/
			async getStatic (state, restaurantId, context = false) {
				if (state.ignoreStatic || await this.isStaticCall() === false || context === false) {
					return false;
				}

				// TODO: Previously static call checker
				if (state.isInfoStatic === false && [staticContext.menu, staticContext.deliveryMenu].includes(context)) {
					return false;
				}

				try {
					return await api({
						path: `/s3-cache/menu/${restaurantId}/${$.locale}/${context}.json`,
						method: "GET",
					});
				}
				catch (error) {
					return false;
				}
			},
			/** END: STATIC CALLS SERVICES **/
			async getRestaurantInfo (state, {restaurantId}) {
				this.clearState();

				let response = {
					result: await this.getStatic(restaurantId, staticContext.info),
				};

				if (!response.result) {
					response = await api({
						path: `/api/clients/menu/restaurant/${restaurantId}`,
						method: "GET",
						headers: {"content-type": "application/json"},
					});
					this.isInfoStatic = false;
				}
				else {
					state.isInfoStatic = true;
				}

				if (response.errCode || response.errText) {
					// console.error(response.errText);
					throw new Error(response.errText);
				}

				state.restaurant = Object.assign(state.restaurant, await helpers.prepareMainRestaurantInfo(response.result), {id: restaurantId});
				return response.result;
			},
			async getDeliveryRestaurantInfo (state, {restaurantId}) {
				this.clearState();
				let response = {
					result: await this.getStatic(restaurantId, staticContext.deliveryInfo),
				};

				if (!response.result) {
					response = await api({
						path: `/api/clients/menu/restaurant/delivery/${restaurantId}`,
						method: "GET",
						headers: {"content-type": "application/json"},
					});
					this.isInfoStatic = false;
				}
				else {
					state.isInfoStatic = true;
				}

				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errText);
				}
				state.restaurant = Object.assign(state.restaurant, await helpers.prepareMainRestaurantInfo(response.result), {id: restaurantId});
				this.checkIsRestaurantOpen(state);
				this.checkIsOrderAllowed(state);
				return response.result;
			},
			async getAvailableLang (state, {restaurantId}) {
				const response = await api({
					path: "/api/s2p/restaurant/languages",
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: JSON.stringify({restaurantId}),
				});
				console.log("getAvailableLang response", response);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errText);
				}
				helpers.mutateAvailableLangs(state, {data: response});
				return response;
			},
			async getAllMenu (state, {restaurantId, menuId = state.restaurant.menus[0].id, update}) {
				if (!update && state.resMenu?.dishes) {
					return state.resMenu;
				}

				let response = {
					result: await this.getStatic(restaurantId, staticContext.menu),
				};

				if (!response.result) {
					response = await api({
						path: `/api/clients/menu/menu/v2/${restaurantId}`,
						method: "GET",
						headers: {"content-type": "application/json"},
					});
				}

				console.log("getRestaurantMenu response", response);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errText);
				}
				state.setTagsList(response.result?.tags || []);
				state.setAllergensList(response.result?.allergens || []);
				state.setMenusList(response.result?.menus || []);
				state.setAllergenOptions(response.result?.allergenOptions || {});
				state.setDishesList(response.result?.dishes || []);
				state.setAvailableSets();
				state.setCategoriesList(response.result?.categories || []);
				state.setSpecialsList(response.result?.specials || []);
				state.resMenu = response.result;
				return response.result;
			},
			async getAllDeliveryMenu (state, {restaurantId, menuId = state.restaurant.menus[0].id, update}) {
				if (!update && state.resMenu?.dishes) {
					return state.resMenu;
				}

				let response = {
					result: await this.getStatic(restaurantId, staticContext.deliveryMenu),
				};

				if (!response.result) {
					response = await api({
						path: `/api/clients/menu/menu/delivery/v2/${restaurantId}`,
						method: "GET",
						headers: {"content-type": "application/json"},
					});
				}
				else {
					state.isMenuStatic = true;
				}

				console.log("getRestaurantMenu response", response);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errText);
				}
				state.setTagsList(response.result?.tags || []);
				state.setAllergensList(response.result?.allergens || []);
				state.setMenusList(response.result?.menus || []);
				state.setAllergenOptions(response.result?.allergenOptions || {});
				state.setDishesList(response.result?.dishes || []);
				state.setAvailableSets();
				state.setCategoriesList(response.result?.categories || []);
				state.setSpecialsList(response.result?.specials || []);
				state.resMenu = response.result;
				return response.result;
			},
			async addBasketItem (state, payload, modifiers) {
				let body;
				const {dishVariantId} = payload;
				body = {...payload};

				if (modifiers?.length) {
					body = {
						...body,
						modifiers,
					};
				}

				console.log("addBasketItem body", body);
				const response = await api({
					path: "/api/clients/menu/basket/item",
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: JSON.stringify(body),
				});
				console.log("post response", response);
				if (response.errCode || response.errText) {
					if (response.errText === `DishVariant ${dishVariantId} in stop list`) {
						state.updateBasketStopListItem(dishVariantId);
						state.updateDishListItemStopList(dishVariantId);
						state.updateOrderTotal(dishVariantId);
						return API_STATUS.OUTOFSTOCK;
					}
					else {
						console.error(response.errText);
						throw new Error(response.errText);
					}
				}
				return response.result;
			},
			async editBasketItem (state, payload) {
				const {itemId, dishVariantId, quantity} = payload;
				const body = {
					itemId,
					quantity,
				};
				console.log("editBasketItem body", body);

				const response = await api({
					path: "/api/clients/menu/basket/item",
					method: "PUT",
					headers: {
						"content-type": "application/json",
					},
					body: JSON.stringify(body),
				});
				console.log("put dish response", response);

				if (response.errCode || response.errText) {
					if (response.errText === `DishVariant ${dishVariantId} in stop list`) {
						state.updateBasketStopListItem(dishVariantId);
						state.updateDishListItemStopList(dishVariantId);
						state.updateOrderTotal(dishVariantId);
						return API_STATUS.OUTOFSTOCK;
					}
					else {
						console.error(response.errText);
						throw new Error(response.errText);
					}
				}
				return response.result;
			},
			async getBasket (state, {restaurantId, basketType}) {
				let response = await api({
					path: `/api/clients/menu/basket/${restaurantId}?basketType=${basketType}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				console.log("basket response", response);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errText);
				}
				if (response.result) {
					state.updateOrder(response.result);
					state.updateBasket(response.result?.items);

					if (state.dishes.length) {
						state.updateDishesInList(response.result?.items);
					}
				}
				else {
					state.basket = [];
					state.order = {};
				}
				return state.order;
			},
			async postCutlery (state, {restaurantId, quantity}) {
				const body = {
					restaurantId,
					quantity,
				};
				let response = await api({
					path: "/api/clients/menu/basket/cutlery",
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: JSON.stringify(body),
				});
				console.log("cutlery response", response);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errText);
				}
				if (response.result == "ok") {
					console.log("cutlery", response.result);
					state.order.cutlery = quantity;
				}
				return state.order;
			},
			async getDeliveryCost (state, body, restaurantId) {
				console.log("body", body);
				const response = await api({
					path: `/api/clients/menu/delivery-cost/${restaurantId}`,
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: JSON.stringify(body),
				});
				console.log("get delivery cost response", response);
				if (response.errCode || response.errText) {
					console.error(response);
					throw new Error(response.errText, {cause: response});
				}
				return response.result;
			},
			async payBasket (state, payload) {
				console.log("body", payload);
				const response = await api({
					path: `/api/clients/menu/basket/pay`,
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: JSON.stringify(payload),
				});
				console.log("pay basket response", response.result);
				if (response.errCode || response.errText) {
					console.error(response);
					throw new Error(response.errText, {cause: response});
				}
				return response.result;
			},
			async getTipsOption (state, {restaurantId, sum}) {
				const response = await api({
					path: `/api/clients/menu/restaurant/tip-options/${restaurantId}?sum=${sum}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				console.log("get tips response", response);
				if (response.errCode || response.errText) {
					console.error(response);
					throw new Error(response.errText, {cause: response});
				}
				state.setTipOptions(response.result);
				return response.result;
			},
			async getPaymentStatus (state, orderNumber) {
				const response = await api({
					path: `/api/clients/menu/order/${orderNumber}/payment-status`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				console.log("payment status response", response.result);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errText);
				}
				// state.activeOrders[basketId] = response.result.paymentStatus;
				// return state.activeOrders[basketId].paymentStatus;
				return response.result;
			},
			async getOrderInfo (state, orderNumber) {
				const response = await api({
					path: `/api/clients/menu/order/${orderNumber}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				console.log("order info response", response.result);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errText);
				}
				state.activeOrders[orderNumber] = Object.assign({}, response.result);
				return state.activeOrders[orderNumber];
			},
			async getActiveOrders (state, restaurantId, deliveryOption) {
				const response = await api({
					path: `/api/clients/menu/order/active/${restaurantId}?deliveryOption=${deliveryOption}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				console.log("active orders response", response.result);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errText);
				}

				helpers.mutateActiveOrders(state, {data: response.result});
				return response.result;
			},
			async getAddressList (state, restaurantId, address) {
				const response = await api({
					path: `/api/clients/menu/delivery-address?restaurantId=${restaurantId}&deliveryAddress=${address}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				console.log("deliveryAddress response", response.result);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errText);
				}
				return response.result;
			},
			async getEatList (state, listId) {
				const response = await api({
					path: `/api/bfm/v1/eatlist?publicId=${listId}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				console.log("get eat list response", response);
				if (response.errCode || response.errText) {
					console.error(response);
					state.updateEatList({});
					throw new Error(response.errText, {cause: response});
				}
				else {
					state.updateEatList(response.result);
				}
				return response?.result;
			},
			async fetchPromo (state, id) {
				const response = await api({
					path: `/api/data-view/v1/treats/promo/${id}`,
					hostReplace: false,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				if (response.errCode || response.errText) {
					console.error(response);
					state.updatePromo({});
					throw new Error(response.errText, {cause: response});
				}
				else {
					state.updatePromo(response?.result);
				}
				return response?.result;
			},
			updatePromo (state, data) {
				state.promoData = Object.assign({}, data);
			},
			async fetchGiveaway (state, id) {
				const response = await api({
					path: `/api/clients/v1/giveaway/${id}`,
					hostReplace: false,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				if (response.errCode || response.errText) {
					console.error(response);
					state.updateGiveaway({});
					throw new Error(response.errText, {cause: response});
				}
				else {
					state.updateGiveaway(response?.result);
				}
				return response?.result;
			},
			updateGiveaway (state, data) {
				state.giveawayData = Object.assign({}, data);
			},
			getWishList (state, {restaurantId}) {
				if (typeof window !== "undefined") {
					const data = localStorage.getItem(restaurantId);
					if (data) {
						const res = JSON.parse(data);
						state.wishList = [...res.wishList];
					}
				}

				return state.wishList;
			},
			setWishList (state, list, {restaurantId}) {
				const data = {
					wishList: list,
				};
				localStorage.setItem(restaurantId, JSON.stringify(data));
				// state.wishList = [...list];
				this.getWishList({restaurantId});
			},
			setWishItem (state, item, isWish, {restaurantId}) { // by variant
				if (isWish) {
					state.wishList.push(item);
				}
				else {
					state.wishList = state.wishList.filter(el => el.id !== item.id);
				}
				this.setWishList(state.wishList, {restaurantId});
			},
			addItemToWishList (state, item, {restaurantId}) { // by all dish
				state.wishList.push(item);
				this.updateItemWishStateInDishList(item.id, true);
				this.setWishList(state.wishList, {restaurantId});
				event("add_bookmark", {
					"event_category": "bookmark",
				});
			},
			deleteItemInWishList (state, item, {restaurantId}) { // by all dish
				state.wishList = state.wishList.filter(el => el.id !== item.id);
				this.updateItemWishStateInDishList(item.id, false);
				this.setWishList(state.wishList, {restaurantId});
				event("remove_bookmark", {
					"event_category": "bookmark",
				});
			},
			updateItemWishStateInDishList (state, dishId, isWish = false) { // by all dish
				state.dishes.forEach(el => {
					if (el.id === dishId) {
						el.wishItem = isWish;
					}
				});
			},
			updateWishListByLang (state, dishes, {restaurantId}) { // by all dish
				const list = this.getWishList({restaurantId});

				// clear storage and store
				this.setWishList([], {restaurantId});

				dishes.forEach(el => {
					const elem = list.find(wishDish => wishDish.id === el.id);
					if (elem) {
						el.wishItem = true;
						this.addItemToWishList({...el}, {restaurantId});
					}
				});

				// crazy stoplist logic in wishlist
				const list2 = this.getWishList({restaurantId});

				if (list.length > list2.length) {
					list.forEach(el => {
						const elem = list2.find(v => v.id === el.id);
						if (!elem) {
							this.setWishItem({...el, stopList: true}, true, {restaurantId});
						}
					});
				}
			},
			setWishListByLang (state, dishes, {restaurantId}) { // by variant
				const list = this.getWishList({restaurantId});

				// clear storage and store
				this.setWishList([], {restaurantId});

				dishes.forEach(el => {
					const elem = list.find(wishDish => wishDish.dishId === el.id);
					if (elem) {
						el.dishVariants.forEach(v => {
							const wv = list.find(wd => wd.id === v.id);
							if (wv) {
								v.wishItem = true;
								this.setWishItem({...v, title: el.title, imageUrl: el.imageUrl}, true, {restaurantId});
							}
						});
					}
				});

				// crazy stoplist logic in wishlist
				const list2 = this.getWishList({restaurantId});

				if (list.length > list2.length) {
					list.forEach(el => {
						const elem = list2.find(v => v.id === el.id);
						if (!elem) {
							this.setWishItem({...el, stopList: true}, true, {restaurantId});
						}
					});
				}
			},
			checkIsRestaurantOpen (state) {
				if (!state.restaurant?.settings || !state.restaurant.workScheduleAvailable) {
					return;
				}

				const todayTimeObject = state.restaurant?.settings.find(el => el.dayOfWeek === helpers.getCurrentTimezoneMomentDate().isoWeekday());
				if (!todayTimeObject?.active) {
					state.isRestaurantOpen = false;
					return;
				}

				const restoStartMoment = helpers.getMomentDateByTime(todayTimeObject.startTime);
				const restoEndMoment = helpers.getMomentDateByTime(todayTimeObject.endTime);
				state.isRestaurantOpen = helpers.getCurrentTimezoneMomentDate().isBetween(restoStartMoment, restoEndMoment);
				return state.isRestaurantOpen;
			},
			checkDeliveryTime (state) {
				console.log("checking delivery time");
				if (!state.restaurant?.deliverySettings) {
					return;
				}

				const deliveryStartMoment = helpers.getMomentDateByTime(state.restaurant.deliverySettings.startTime);
				const deliveryEndMoment = helpers.getMomentDateByTime(state.restaurant.deliverySettings.endTime);

				state.restaurant.isDeliveryTimeAvailable = helpers.getCurrentTimezoneMomentDate().isBetween(deliveryStartMoment, deliveryEndMoment);
			},
			checkPosibleDeliveryOptions (state) {
				if (!state.restaurant?.deliverySettings) {
					return;
				}

				const deliveryOptionsList = state.restaurant?.deliverySettings.deliveryOptions || [];
				const minOrderAmountForDelivery = state.restaurant?.deliverySettings.minOrderAmount || 0;
				const amount = state?.order.totalAmount || 0;

				state.isDeliveryOptionPosible = !!((helpers.getNumberDifference(amount, minOrderAmountForDelivery) >= 0 || amount == 0) && deliveryOptionsList?.find((el) => el === DELIVERY_OPTIONS.DELIVERY));
				// debugger
				state.isDeliveryOptionPresent = deliveryOptionsList?.includes(DELIVERY_OPTIONS.DELIVERY);
				state.isPickUpOptionPresent = deliveryOptionsList?.includes(DELIVERY_OPTIONS.PICKUP);
			},
			checkIsOrderAllowed (state) {
				if (!state.restaurant?.deliverySettings || !state.restaurant.workScheduleAvailable) {
					state.orderNotAllowed = true;
					return;
				}

				const deliveryOptionsList = state.restaurant?.deliverySettings.deliveryOptions || [];
				state.orderNotAllowed = deliveryOptionsList?.includes(DELIVERY_OPTIONS.NONE);

				if (state.orderNotAllowed) {
					state.displayAlert("orderNotAllowed");
				}
				else {
					state.hideAlert("orderNotAllowed");
				}
				return state.orderNotAllowed;
			},
			displayAlert (state, type) {
				let date;
				let minSum;
				if (type === "closed" || type === "deliveryTime") {
					date = helpers.findNextWorkingDate(state.restaurant?.settings, state.restaurant?.workScheduleAvailable);
					console.log("date", date);
				}
				if (type === "delivery") {
					minSum = state.restaurant?.deliverySettings?.minOrderAmount;
				}
				const data = [
					{
						type: "closed",
						message: date ? `${date.day}, ${date.time}` : "",
					},
					{
						type: "delivery",
						message: `${minSum} ${state.ccy}` || "",
					},
					{
						type: "deliveryTime",
						message: date ? `${date.day}, ${date.time}` : "",
					},
					{
						type: "orderNotAllowed",
						message: "",
					},
				];
				state.alertContent[type] = data.find(el => el.type === type);
				state.showAlert[type] = true;
			},
			hideAlert (state, type) {
				state.showAlert[type] = false;
				state.alertContent[type] = {};
			},
			setAvailableSets (state) {
				// create sets for dishes not in stopList
				state.availableCategories.clear();
				state.availableSpecials.clear();
				state.dishes.forEach(d => {
					if (!d.stopList) {
						state.availableCategories.add(d.categoryId);
						d.specials?.map(s => state.availableSpecials.add(s));
					}
					state.availableCategories.add(d.categoryId);
					d.specials?.map(s => state.availableSpecials.add(s));
				});
			},
			setCategoriesList (state, categories) {
				state.categories = [];
				// filter categories where all dishes have stopList = true
				categories.forEach(c => {
					if (state.availableCategories.has(c.id)) {
						state.categories.push(c);
					}
				});
			},
			setTagsList (state, tags) {
				state.tags = [...tags];
			},
			setSpecialsList (state, specials) {
				state.specials = [];
				// filter specials where all dishes have stopList = true
				specials.forEach(s => {
					if (state.availableSpecials.has(s.id)) {
						state.specials.push(s);
					}
				});
			},
			setAllergensList (state, allergens) {
				state.allergens = [...allergens];
			},
			setMenusList (state, menus) {
				state.menus = [...helpers.configurateMenuSectionSchedule(menus)];
			},
			setDishesList (state, dishes) {
				(dishes || []).forEach(el => {
					el.showMore = false;
					el.wishItem = false;
					el.stopList = el?.dishVariants.every(v => v.stopList);
					el.hasVariants = el?.dishVariants?.length > 1;

					el.tags = (el.tags || []).map(t => {
						const currentTag = state.getTagById(t.id);
						if (currentTag) {
							t.name = currentTag.name;
							t.bgColor = currentTag.bgColor || "rgb(253, 237, 226)";
							if (currentTag.bgColor) {
								t.color = helpers.blackOrWhite(currentTag.bgColor);
							}
						}
						if (currentTag?.iconUrl) {
							t.iconUrl = currentTag.iconUrl;
						}
						return t;
					});
				});
				state.dishes = [...this.filterStopListProperty(dishes)];
			},
			filterStopListProperty (state, items) {
				return items.filter(el => !el.stopList);
			},
			setAllergenOptions (state, options) {
				state.allergenOptions = {...options};
			},
			setTipOptions (state, data) {
				state.tipOptions = [...data];
			},
			// getters
			getDishById (state, dishId) {
				return state.dishes.find(el => el.id === dishId);
			},
			getTagById (state, tagId) {
				return state.tags.find(el => el.id === tagId);
			},
			getAllergenById (state, allergenId) {
				return state.allergens.find(el => el.id === allergenId);
			},
			// dish in dishlist
			updateDishesInList (state, basketItems) {
				basketItems.forEach(basketElem => {
					const dish = state.dishes.find(el => el.id === basketElem.dishId);
					if (dish) {
						dish.dishVariants.forEach(el => {
							if (el.id === basketElem.dishVariantId) {
								// el.basketItemId = basketElem.itemId;
								// el.basketQt = el.basketQt ? el.basketQt + basketElem.quantity : basketElem.quantity;
								el.stopList = basketElem.stopList; // update stoplist prop
								el.description = basketElem.variantDescription || "";
							}
						});
						dish.title = basketElem.title || "";
						dish.description = basketElem.dishDescription || "";
						dish.imageUrl = basketElem.imageUrl || "";
					}
				});
			},
			updateDishItem (state, {itemId, dishVariantId, quantity}) {
				let dishVariant;
				state.dishes.forEach(el => {
					const index = el.dishVariants.findIndex(v => v.id === dishVariantId);
					if (index >= 0) {
						dishVariant = el.dishVariants[index];
						dishVariant.basketItemId = itemId;
						dishVariant.basketQt = dishVariant.basketQt ? dishVariant.basketQt + quantity : quantity;
					}
				});
			},
			updateDishListItemStopList (state, variantId) {
				state.dishes.forEach(el => {
					const variant = el.dishVariants.find(v => v.id === variantId);
					if (variant) {
						variant.stopList = true;
					}
				});
			},
			// basket
			updateBasket (state, basket) {
				state.basket = [...basket];
			},
			updateBasketItem (state, {itemId, quantity, dishVariantId}) {
				console.log("updateBasketItem", itemId, dishVariantId);
				const elem = state.basket.find(el => el.itemId === itemId);
				if (quantity === 0) {
					state.basket = [...state.basket.filter(el => el.itemId !== itemId)];
				}
				else if (elem) {
					elem.quantity = quantity;
				}

				state.updateOrderTotal();
			},
			updateBasketStopListItem (state, variantId) {
				const elems = state.basket.filter(el => el.dishVariantId === variantId);
				if (elems?.length) {
					elems.forEach(el => {
						el.stopList = true;
						el.quantity = 0;
					});
				}
			},
			// order
			updateOrder (state, order) {
				state.order = Object.assign({}, order);
			},
			/**
			 * Gets called in methods updateBasketItem, postDish
			 * Calculating total order price. Update state.order.totalAmount
			 */
			updateOrderTotal (state) {
				// const orderAmount = helpers.calcAmount(state.basket.filter(el => !el.stopList), "quantity");
				const orderAmount = state.basket.filter(el => !el.stopList)?.reduce((acc, cur) => {
					acc += Number(cur.price) * Number(cur.quantity);
					return acc;
				}, 0);
				if (orderAmount.toString().includes(".")) {
					state.order.totalAmount = orderAmount.toFixed(2);
				}
				else {
					state.order.totalAmount = orderAmount;
				}
			},
			updateEatList (state, data) {
				state.eatListInfo = {...data};
				if (data?.wishedDishes) {
					state.eatList = data.wishedDishes.reduce((acc, cur) => {
						(cur?.dishes || []).map(d => acc.push({
							...d,
							restaurant: {
								name: cur.restaurant.name,
								iconUrl: cur.restaurant.iconUrl,
								menu: cur.restaurant.menu,
								disabled: cur.restaurant.disabled,
							},
						}));
						return acc;
					}, []);
				}
				else {
					state.eatList = [];
				}
			},
			// payment project
			async getPaymentInfo (state, {restaurantId, table}) {
				let response = await api({
					path: `/api/bills/restaurant-info?restaurantId=${restaurantId}&tableCode=${table}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				console.log("payment response", response);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errCode);
				}
				if (response.result) {
					state.payment.info = Object.assign({}, response.result);
				}
				return state.payment.info;
			},
			async getBills (state, {restaurantId, table}) {
				let query = `?restaurantId=${restaurantId}`;
				if (table.match(/[a-zA-Z]/g)) {
					query += `&tableCode=${table}`;
				}
				else {
					query += `&tableNumber=${table}`;
				}
				let response = await api({
					path: `/api/bills/table${query}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				console.log("payment response", response);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errCode);
				}
				if (response) {
					helpers.setPaymentInfo(state, response);
				}
				return state.payment;
			},
			async payBill (state, {restaurantId, tableCode, billId, sum, tips, order}) {
				let body = {
					restaurantId,
					billId,
					sum,
					tips: 0,
					tableCode,
					order,
				};
				let response = await api({
					path: "/api/bills/invoice",
					method: "POST",
					headers: {
						"content-type": "application/json",
					},
					body: JSON.stringify(body),
				});
				console.log("payment response", response);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errCode);
				}
				if (response.result) {
					console.log("response", response);
					state.payment.link = response.result.link;
				}
				return state.payment.link;
			},
			async getInvoice (state, {invoiceId}) {
				const response = await api({
					path: `/api/bills/invoice/${invoiceId}`,
					method: "GET",
					headers: {
						"content-type": "application/json",
					},
				});
				// console.log("get invoice response", response);
				if (response.errCode || response.errText) {
					console.error(response.errText);
					throw new Error(response.errCode);
				}
				if (response.result) {
					helpers.setInvoiceInfo(state, response.result);
				}
				return state.payment.invoice;
			},
			install (state, app) {
				app.provide(key, state);
			},
		},
	});

	watch(() => accessor.theme, () => {
		$.cookies.set("theme", accessor.theme);
		console.log("new theme", accessor.theme);
	});

	watch(() => accessor.order.totalAmount, () => {
		accessor.checkPosibleDeliveryOptions();

		if (accessor.isDeliveryOptionPresent && !accessor.isDeliveryOptionPosible) {
			accessor.displayAlert("delivery");
		}
		else {
			accessor.hideAlert("delivery");
		}
	}, {deep: true});

	watch(() => accessor.isRestaurantOpen, (newVal) => {
		if (!newVal) {
			accessor.displayAlert("closed");
		}
		else {
			accessor.hideAlert("closed");
		}
	});

	watch(() => accessor.restaurant.isDeliveryTimeAvailable, (newVal) => {
		accessor.checkPosibleDeliveryOptions();
		if (!newVal && !accessor.isPickUpOptionPresent) {
			accessor.displayAlert("deliveryTime");
		}
		else {
			accessor.hideAlert("deliveryTime");
		}
	});

	return accessor;
}

export function useState () {
	return inject(key);
}
